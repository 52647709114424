import React from "react";
import Container from "../../common/Container";
import SimpleList from "../UI/SimpleList";
import Screenshot from "../UI/Screenshot";
import TrackedLink from "../../Analytics/TrackedLink";
import TRENDS_CONTENT from "./index.content";
import "./index.scss";

const Trends = () => {
  const {
    PRO_LIST,
    PRO_SCREENSHOT,
    NICHE_LIST,
    NICHE_SCREENSHOT,
    SCORES_LIST,
    SCORES_SCREENSHOT,
  } = TRENDS_CONTENT;
  return (
    <section className="PgSE-T">
      <Container>
        <h2 className="PgSE-T__title">How to Check Amazon Sales Data</h2>
        <p className="PgSE-T__description">
          Sales numbers can change daily, so you need to check each product’s
          sales history in order to get the whole picture. By using the most
          up-to-date and accurate sales trackers, checkers, and apps, you’ll
          gain an edge over your competition.
          <span>To do this, follow the steps below:</span>
        </p>
        <div className="PgSE-T-pro">
          <SimpleList
            classes="PgSE-T-List"
            title={PRO_LIST.title}
            items={PRO_LIST.items}
            mod="pro"
          />
          <Screenshot
            screenshot={PRO_SCREENSHOT}
            classes="PgSE-T__screenshot_pro"
          />
        </div>
        <hr className="PgSE-T__hr" />
        <div className="PgSE-T-niche">
          <Screenshot
            screenshot={NICHE_SCREENSHOT}
            classes="PgSE-T__screenshot_niche"
          />
          <SimpleList
            classes="PgSE-T-List"
            title={NICHE_LIST.title}
            items={NICHE_LIST.items}
            mod="niche"
          />
        </div>
        <hr className="PgSE-T__hr" />
        <div className="PgSE-T-scores">
          <SimpleList
            classes="PgSE-T-List"
            title={SCORES_LIST.title}
            items={SCORES_LIST.items}
            mod="scores"
          />
          <Screenshot
            screenshot={SCORES_SCREENSHOT}
            classes="PgSE-T__screenshot_scores"
          />
        </div>
        <TrackedLink
          category="LANDING|Estimator"
          action="TRY AMZSCOUT PRO AI EXTENSION FOR FREE"
          classes="PgSE-T__link"
          target
          path={`${process.env.SCOUT_EXT_PRO_NEW}?utm_source=site&utm_medium=amzscout&utm_campaign=sales_estimator`}
          showMobileProPopup
        >
          TRY AMZSCOUT PRO AI EXTENSION FOR FREE
        </TrackedLink>
      </Container>
    </section>
  );
};

export default Trends;

import React from "react";
import WEBP_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro.webp";
import WEBP_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@2x.webp";
import PNG_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro.png";
import PNG_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@2x.png";
import WEBP_992_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@992.webp";
import WEBP_992_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@992_2x.webp";
import PNG_992_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@992.png";
import PNG_992_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@992_2x.png";
import WEBP_768_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@768.webp";
import WEBP_768_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@768_2x.webp";
import PNG_768_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@768.png";
import PNG_768_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@768_2x.png";
import WEBP_Mob_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@mob.webp";
import WEBP_Mob_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@mob_2x.webp";
import PNG_Mob_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@mob.png";
import PNG_Mob_2x_PRO from "../../../assets/images/raster/pages/sales-estimator/Trends/pro@mob_2x.png";

import WEBP_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche.webp";
import WEBP_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@2x.webp";
import PNG_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche.png";
import PNG_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@2x.png";
import WEBP_992_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@992.webp";
import WEBP_992_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@992_2x.webp";
import PNG_992_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@992.png";
import PNG_992_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@992_2x.png";
import WEBP_768_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@768.webp";
import WEBP_768_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@768_2x.webp";
import PNG_768_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@768.png";
import PNG_768_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@768_2x.png";
import WEBP_Mob_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@mob.webp";
import WEBP_Mob_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@mob_2x.webp";
import PNG_Mob_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@mob.png";
import PNG_Mob_2x_NICHE from "../../../assets/images/raster/pages/sales-estimator/Trends/niche@mob_2x.png";

import WEBP_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores.webp";
import WEBP_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@2x.webp";
import PNG_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores.png";
import PNG_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@2x.png";
import WEBP_992_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@992.webp";
import WEBP_992_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@992_2x.webp";
import PNG_992_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@992.png";
import PNG_992_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@992_2x.png";
import WEBP_768_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@768.webp";
import WEBP_768_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@768_2x.webp";
import PNG_768_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@768.png";
import PNG_768_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@768_2x.png";
import WEBP_Mob_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@mob.webp";
import WEBP_Mob_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@mob_2x.webp";
import PNG_Mob_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@mob.png";
import PNG_Mob_2x_SCORES from "../../../assets/images/raster/pages/sales-estimator/Trends/scores@mob_2x.png";
import TrackedLink from "../../Analytics/TrackedLink";

const TRENDS_CONTENT = {
  PRO_SCREENSHOT: {
    WEBP: WEBP_PRO,
    WEBP_2x: WEBP_2x_PRO,
    PNG: PNG_PRO,
    PNG_2x: PNG_2x_PRO,
    WEBP_992: WEBP_992_PRO,
    WEBP_992_2x: WEBP_992_2x_PRO,
    PNG_992: PNG_992_PRO,
    PNG_992_2x: PNG_992_2x_PRO,
    WEBP_768: WEBP_768_PRO,
    WEBP_768_2x: WEBP_768_2x_PRO,
    PNG_768: PNG_768_PRO,
    PNG_768_2x: PNG_768_2x_PRO,
    WEBP_Mob: WEBP_Mob_PRO,
    WEBP_Mob_2x: WEBP_Mob_2x_PRO,
    PNG_Mob: PNG_Mob_PRO,
    PNG_Mob_2x: PNG_Mob_2x_PRO
  },
  NICHE_SCREENSHOT: {
    WEBP: WEBP_NICHE,
    WEBP_2x: WEBP_2x_NICHE,
    PNG: PNG_NICHE,
    PNG_2x: PNG_2x_NICHE,
    WEBP_992: WEBP_992_NICHE,
    WEBP_992_2x: WEBP_992_2x_NICHE,
    PNG_992: PNG_992_NICHE,
    PNG_992_2x: PNG_992_2x_NICHE,
    WEBP_768: WEBP_768_NICHE,
    WEBP_768_2x: WEBP_768_2x_NICHE,
    PNG_768: PNG_768_NICHE,
    PNG_768_2x: PNG_768_2x_NICHE,
    WEBP_Mob: WEBP_Mob_NICHE,
    WEBP_Mob_2x: WEBP_Mob_2x_NICHE,
    PNG_Mob: PNG_Mob_NICHE,
    PNG_Mob_2x: PNG_Mob_2x_NICHE
  },
  SCORES_SCREENSHOT: {
    WEBP: WEBP_SCORES,
    WEBP_2x: WEBP_2x_SCORES,
    PNG: PNG_SCORES,
    PNG_2x: PNG_2x_SCORES,
    WEBP_992: WEBP_992_SCORES,
    WEBP_992_2x: WEBP_992_2x_SCORES,
    PNG_992: PNG_992_SCORES,
    PNG_992_2x: PNG_992_2x_SCORES,
    WEBP_768: WEBP_768_SCORES,
    WEBP_768_2x: WEBP_768_2x_SCORES,
    PNG_768: PNG_768_SCORES,
    PNG_768_2x: PNG_768_2x_SCORES,
    WEBP_Mob: WEBP_Mob_SCORES,
    WEBP_Mob_2x: WEBP_Mob_2x_SCORES,
    PNG_Mob: PNG_Mob_SCORES,
    PNG_Mob_2x: PNG_Mob_2x_SCORES
  },

  PRO_LIST: {
    title: "Open AMZScout’s PRO AI Chrome Extension",
    items: [
      {
        template: (
          <TrackedLink
            action="Install the AMZScout PRO AI Extension"
            category="SalesEstimatorLP"
            path={`${process.env.SCOUT_EXT_PRO_NEW}?utm_source=site&utm_medium=amzscout&utm_campaign=sales_estimator`}
            target
            showMobileProPopup
          >
            Install the AMZScout PRO AI Extension
          </TrackedLink>
        ),
      },
      {
        template: (
          <p>
            <b>Sign up for free.</b> The amazon.com website will open automatically. Click the “Sign up for free now”
            button and enter your email to start your trial.
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Find a niche or product you want to analyze</b> on Amazon. For example, enter “bookmarks for kids” in the
            Amazon search bar.
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Click the PRO AI Extension icon</b> to open a detailed dashboard to see sales data for the entire niche
            and individual products
          </p>
        ),
      },
    ],
  },
  NICHE_LIST: {
    title: <>Check Sales Trends for a Niche/<br />Product</>,
    items: [
      {
        template: (
          <p>
            <b>Evaluate sales and demand</b> by checking sales numbers for an entire niche and individual products
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Check if the niche has seasonal fluctuations</b> by clicking Niche History at the top of your screen
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Check if product sales are stable or growing.</b> Select a product you would like to research. Click the
            arrow to the left of the product, and then click “Product History”.
          </p>
        ),
      },
    ],
  },
  SCORES_LIST: {
    title: "Evaluate if it’s a Good Product to Sell",
    items: [
      {
        template: (
          <p>
            <b>Estimate any product’s profitability</b> by using the Profit Calculator tool. Pay close attention to
            margins, fees and cost estimates.
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Evaluate the competition.</b> Check the number of reviews, number and quality of competitors, and quality
            of listings in the niche. If there are many other strong sellers in the niche, it will be more difficult to
            increase your product ranking.
          </p>
        ),
      },
      {
        template: (
          <p>
            <b>Check product and niche scores</b> to get an overall evaluation for any niche or product
          </p>
        ),
      },
    ],
  },
};

export default TRENDS_CONTENT;
